import './KnowledgeBaseCategory.scss'

import { graphql } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareSideCardNavigation from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNavigation'
import PuxColumns, {
  PuxMainColumn,
  PuxSideColumn,
} from 'Shared/components/PuxColumns/PuxColumns'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import { KnowledgeBaseMenuItemType } from 'Shared/utils/getKnowledgeBaseData'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

import Layout from '../../components/core/Layout'

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareKnowledgeBaseCategory(first: 1, where: { path: $pagePath }) {
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareKnowledgeBaseCategory {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

interface PageType {
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
}

interface KnowledgeBaseCategoryType {
  data: {
    orchard: {
      easySoftwareKnowledgeBaseCategory: [PageType]
    }
  }
  pageContext: {
    pagePath: string
    widgets: {
      widgetZone: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    knowledgeBaseData: KnowledgeBaseMenuItemType[]
    sideCardWidgets: any
    specificSideCardWidgets: any
  }
}

const KnowledgeBaseCategory: FunctionComponent<KnowledgeBaseCategoryType> = ({
  data,
  pageContext,
}) => {
  const breadcrumbsData = pageContext.breadcrumbs
  return (
    <Layout
      localizedPath={
        data.orchard.easySoftwareKnowledgeBaseCategory[0].localization
          ?.localizations
      }
    >
      <EasySoftwareBreadcrumbs items={breadcrumbsData} showAll={true} />
      <PuxMetaTags {...pageContext.metaTags} />
      <div className='pux-container knowledge-base-category'>
        <PuxColumns>
          <PuxMainColumn>
            <h1>
              {data.orchard.easySoftwareKnowledgeBaseCategory[0].displayText}
            </h1>
            <WidgetBuilder widgetBuilderData={pageContext.widgets.widgetZone} />
          </PuxMainColumn>
          <PuxSideColumn>
            <div className='sidebar-sticky'>
              <EasySoftwareSideCardNavigation
                type='knowledgeBase'
                sideCardNavigationLinks={pageContext.knowledgeBaseData}
              />
              {/* <WidgetBuilder
                widgetBuilderData={
                  pageContext.specificSideCardWidgets ||
                  pageContext.sideCardWidgets
                }
              /> */}
            </div>
          </PuxSideColumn>
        </PuxColumns>
      </div>
    </Layout>
  )
}

export default KnowledgeBaseCategory
